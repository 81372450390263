// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$address-name: 'address';
$address-x: 0px;
$address-y: 60px;
$address-offset-x: 0px;
$address-offset-y: -60px;
$address-width: 57px;
$address-height: 70px;
$address-total-width: 139px;
$address-total-height: 130px;
$address-image: '/assets/template/css/sprites/sprite.png';
$address: (0px, 60px, 0px, -60px, 57px, 70px, 139px, 130px, '/assets/template/css/sprites/sprite.png', 'address', );
$mail-name: 'mail';
$mail-x: 0px;
$mail-y: 0px;
$mail-offset-x: 0px;
$mail-offset-y: 0px;
$mail-width: 78px;
$mail-height: 60px;
$mail-total-width: 139px;
$mail-total-height: 130px;
$mail-image: '/assets/template/css/sprites/sprite.png';
$mail: (0px, 0px, 0px, 0px, 78px, 60px, 139px, 130px, '/assets/template/css/sprites/sprite.png', 'mail', );
$phone-name: 'phone';
$phone-x: 78px;
$phone-y: 0px;
$phone-offset-x: -78px;
$phone-offset-y: 0px;
$phone-width: 61px;
$phone-height: 61px;
$phone-total-width: 139px;
$phone-total-height: 130px;
$phone-image: '/assets/template/css/sprites/sprite.png';
$phone: (78px, 0px, -78px, 0px, 61px, 61px, 139px, 130px, '/assets/template/css/sprites/sprite.png', 'phone', );
$vintage-small-name: 'vintage-small';
$vintage-small-x: 78px;
$vintage-small-y: 61px;
$vintage-small-offset-x: -78px;
$vintage-small-offset-y: -61px;
$vintage-small-width: 47px;
$vintage-small-height: 23px;
$vintage-small-total-width: 139px;
$vintage-small-total-height: 130px;
$vintage-small-image: '/assets/template/css/sprites/sprite.png';
$vintage-small: (78px, 61px, -78px, -61px, 47px, 23px, 139px, 130px, '/assets/template/css/sprites/sprite.png', 'vintage-small', );
$spritesheet-width: 139px;
$spritesheet-height: 130px;
$spritesheet-image: '/assets/template/css/sprites/sprite.png';
$spritesheet-sprites: ($address, $mail, $phone, $vintage-small, );
$spritesheet: (139px, 130px, '/assets/template/css/sprites/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
