.offer-block{
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  @media ($bp-larger-than-desktop) {
    margin-top: 64px;
    margin-bottom: 50px;
  }
  &__img{
    max-width: 100%;
  }
}
.offer-list{
  max-width: 976px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Arial', sans-serif;
  &__header{
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    @media ($bp-larger-than-tablet) {
      font-size: 36px;
      margin-bottom: 54px;
    }
  }
  &__list {
    background-color: #efefef;
    padding: 12px 12px;
    margin-bottom: 22px;
    @media ($bp-larger-than-tablet) {
      padding: 15px 21px;
      margin-bottom: 44px;
    }
    p {
      font-size: 14px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 22px;
      line-height: 146%;
      font-family: 'Arial', sans-serif;
      @media ($bp-larger-than-tablet) {
        font-size: 24px;
        margin-bottom: 39px;
      }
      &:last-child{
        margin-bottom: 8px;
      }
    }
  }
  &__lead{
    margin-bottom: 35px;
    .content-lead__button{
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      @media ($bp-larger-than-tablet) {
        max-width: 540px;
      }
    }
  }
}

#leadform{
  padding: 50px;
  max-width: 900px;
  background-color: #e9e9e9;
}